module.exports = {
	siteTitle: "Garena Speed Drifters", // Site title.
	siteTitleShort: "Garena Speed Drifters", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
	siteTitleAlt: "Garena Speed Drifters", // Alternative site title for SEO.
	siteLogo: "/logos/logo-1024.png", // Logo used for SEO and manifest.
	siteUrl: "https://speed.garena.com", // Domain of your website without pathPrefix.
	pathPrefix: "/main", // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
	siteDescription: "Garena Speed Drifters. One of the most important game feature is the Nitro. Charge up your N-tank by drifting and unleash it to achieve your maximum speed! ", // Website description used for RSS feeds/meta description tag.
	siteRss: "/rss.xml", // Path to the RSS file.
	siteFBAppID: "1397686313654456", // FB Application ID for using app insights
	googleAnalyticsID: "UA-41152417-48", // GA tracking ID.
	dateFromFormat: "DD MMMM YYYY", // Date format used in the frontmatter.
	dateFormat: "DD/MM/YYYY", // Date format for display.
	themeColor: "#000000", // Used for setting manifest and progress theme colors.
	backgroundColor: "#000000", // Used for setting manifest background color.
	newsTab: ["all", "update", "event"],
	// guideTab: ["mode", "car", "maps", "accessories", "pets"],
	guideTab: ["mode", "car", "maps"],
	langList: ["id", "en"],
	translate: {
		id: {
			guide: ["mode", "mobil", "sirkuit", "accessories", "pet"],
			news: ["semua", "update", "event"],
			home: {
				section_title_1 : "Berita Terbaru",
				section_title_2 : "Fitur",
			}
		},
		en: {
			guide: ["mode", "car", "map", "accessories", "pet"],
			news: ["all", "update", "event"],
			home: {
				section_title_1 : "Latest News",
				section_title_2 : "Feature",
			}
		}
	}
};
