import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

export default props =>  (
    <StaticQuery
        query={
            graphql`
            {
                id: directusConfigList(region_id: {eq: "ID"}) {
                    googleplay
                    appstore
                }
                en: directusConfigList(region_id: {eq: "EN"}) {
                    googleplay
                    appstore
                }
            }
            `
        }
        render={
            data => 
            {
                let lang = props.lang.lang;
                return (
                    <div className="btn-container">
                        <a href={data[lang].googleplay} target="_blank">
                            <img src={require('assets/img/Download/Button_Playstore.png')} alt=""/>
                        </a>
                        <a href={data[lang].appstore} target="_blank">
                            <img src={require('assets/img/Download/Button_Appstore.png')} alt=""/>
                        </a>
                    </div>
                )
            }
        }
    />
)