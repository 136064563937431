import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';

export default props =>  (
    <StaticQuery
        query={
            graphql`
            {
                id: directusConfigList(region_id: {eq: "ID"}) {
                  region_id
                  facebook
                  instagram
                  youtube
                  support_link
                  download_link
                }
                en: directusConfigList(region_id: {eq: "EN"}) {
                  region_id
                  facebook
                  instagram
                  youtube
                  support_link
                  download_link
                }
            }
            `
        }
        render={
            data => 
            {
                let isopen = props.isopen;
                let lang = props.lang.lang;
                return (
                    <div id="Navbar">
                        <div className={"hamburger hamburger--spin " + (isopen ? " is-active" : "")} onClick={() => props.handleOpen()}>
                            <span className="hamburger-box">
                                <span className="hamburger-inner"></span>
                            </span>
                        </div>
                        <div className={"menu " + ((isopen) ? "active" : "")}>
                            <ul>
                                <li>
                                    <span>Guide</span>
                                    <ul className="sub">
                                        {props.generateNavGuide(lang)}
                                    </ul>
                                </li>
                                <li>
                                    <span>{(lang === "en") ? "News" : "Berita"}</span>
                                    <ul className="sub">
                                        {props.generateNavNews(lang)}
                                    </ul>
                                </li>
                                <li>
                                    <a href={data[lang].support_link} target="_blank">Support</a>
                                </li>
                            </ul>
                        </div>
                        <div className="left">
                            <ul>
                                <li className="dropdown">
                                    <span>Guide</span>
                                    <ul className="dropdown--list">
                                        {props.generateNavGuide(lang)}
                                    </ul>
                                </li>
                                <li className="dropdown">
                                    <span>{(lang === "en") ? "News" : "Berita"}</span> 
                                    <ul className="dropdown--list">
                                        {props.generateNavNews(lang)}
                                    </ul>
                                </li>
                                <li>
                                    <a href={data[lang].support_link} target="_blank">Support</a>
                                </li>
                            </ul>
                        </div>
                        <div className="center">
                            <Link to={`/${lang}`}>
                                <img src={require('assets/img/Header/Logo.png')} alt="" />
                            </Link>
                        </div>
                        <div className="right">
                            <ul>
                                <li>
                                    <Link to={`/${lang}/rate`}>
                                        Rate
                                    </Link>
                                </li>
                                <li style={(lang === "en") ? {display:"none"} : {}}>
                                    <a target="_blank" href="https://fairplay.speed.garena.co.id/">Fairplay</a>
                                </li>
                                <li className="btn--download">
                                    <a target="_blank" onClick={() => props.actions.open_modal(true)}>
                                        <img src={require('assets/img/Header/Button_Download.png')} alt="" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                )
            }
        }
    />

)
