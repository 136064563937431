import React, { Component } from "react";
import Helmet from "react-helmet";
import urljoin from "url-join";
import config from "data/SiteConfig";

class SEO extends Component {
	render() {
		const { postNode, postPath, postSEO } = this.props;
		let title;
		let description;
		let image;
		let postURL;
		if (postSEO) {
			const postMeta = postNode.frontmatter;
			({ title } = postMeta);
			description = postMeta.description
				? postMeta.description
				: postNode.excerpt;
			image = postMeta.cover;
			postURL = urljoin(config.siteUrl, config.pathPrefix, postPath);
		} else {
			title = config.siteTitle;
			description = config.siteDescription;
			image = config.siteLogo;
		}

		image = urljoin(config.siteUrl, config.pathPrefix, image);
		const blogURL = urljoin(config.siteUrl, config.pathPrefix);
		const schemaOrgJSONLD = [
			{
				"@context": "http://schema.org",
				"@type": "VideoGame",
				publisher: "Garena",
				url: blogURL,
				name: title,
				description: "Multiplayer Online Racing Game for mobile platforms.",
				alternateName: config.siteTitleAlt ? config.siteTitleAlt : "",
				genre: ["Racing", "MMO", "Action"],
				image: "https://speed.garena.co.id/main/static/BG1-b2c6e6ed67423185b48d6c0fea6e95dd.jpg",
				screenshot: "https://speed.garena.co.id/main/static/BG1-b2c6e6ed67423185b48d6c0fea6e95dd.jpg",
				operatingSystem: [
					"Android",
					"iOS"
				],
				applicationCategory: [
					"Game",
					"Mobile application"
				],
				aggregateRating: {
					"@type": "AggregateRating",
					"ratingValue": "4.25",
					"reviewCount": "400671"
				},
				offers: {
					"@type": "AggregateOffer",
					"lowPrice": "0.99",
					"highPrice": "99.99",
					"priceCurrency": "USD"
				},
				exampleOfWork: [
					{
						"@type": "VideoGame",
						"name": "Garena Speed Drifters",
						"gamePlatform": [
							"https://en.wikipedia.org/wiki/Android_(operating_system)",
							"https://en.wikipedia.org/wiki/IOS"
						],
						"contentRating": "ESRB Teen",
						"aggregateRating": {
							"@type": "AggregateRating",
							"ratingValue": "4.25",
							"reviewCount": "400671"
						},
						"applicationCategory": "Game, Mobile Application",
						"offers": {
							"@type": "AggregateOffer",
							"lowPrice": "0.99",
							"highPrice": "99.99",
							"priceCurrency": "USD"
						},
						"operatingSystem": [
							"Android",
							"iOS"
						],
					}
				]
			}
		];
		if (postSEO) {
			schemaOrgJSONLD.push(
				{
					"@context": "http://schema.org",
					"@type": "BreadcrumbList",
					itemListElement: [
						{
							"@type": "ListItem",
							position: 1,
							item: {
								"@id": postURL,
								name: title,
								image
							}
						}
					]
				},
				{
					"@context": "http://schema.org",
					"@type": "BlogPosting",
					url: blogURL,
					name: title,
					alternateName: config.siteTitleAlt ? config.siteTitleAlt : "",
					headline: title,
					image: {
						"@type": "ImageObject",
						url: image
					},
					description
				}
			);
		}
		return (
			<Helmet>
				{/* General tags */}
				<title>{title}</title>
				<link rel="shortcut icon" href={require('assets/img/favicon.ico')} type="image/x-icon" />
				<link rel="icon" href={require('assets/img/favicon.ico')} type="image/x-icon" />
				<meta name="description" content={description} />
				<meta name="image" content={image} />

				{/* Schema.org tags */}
				<script type="application/ld+json">
					{JSON.stringify(schemaOrgJSONLD)}
				</script> 

				{/* OpenGraph tags */}
				<meta property="og:url" content={postSEO ? postURL : blogURL} />
				{postSEO ? <meta property="og:type" content="article" /> : null}
				<meta property="og:title" content={title} />
				<meta property="og:description" content={description} />
				<meta property="og:image" content={image} />
				<meta
					property="fb:app_id"
					content={config.siteFBAppID ? config.siteFBAppID : ""}
				/>
			</Helmet>
		);
	}
}

export default SEO;
