import React, { Component } from 'react';
import { Link } from "gatsby";
import siteConfig from "data/SiteConfig";
import NavComponent from './NavComponent';

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as downloadActions from 'redux/actions/download';

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isopen: false
        }
    }

    handleOpen = () => {
        this.setState({
            isopen: !this.state.isopen
        })
    }

    generateNavGuide = (lang) => {
        const guide = siteConfig.guideTab;
        return guide.map((data, index) =>
            <li key={index}>
                <Link to={`/${lang}/guide/${data}`}>{siteConfig.translate[lang.toLowerCase()].guide[index]}</Link>
            </li>
        )
    }

    generateNavNews = (lang) => {
        const news = siteConfig.newsTab;
        return news.map((data, index) =>
            <li key={index}>
                <Link to={`/${lang}/news/${data}`}>{siteConfig.translate[lang.toLowerCase()].news[index]}</Link>
            </li>
        )
    }

    render() {
        return (
            <NavComponent 
                {...this.state} 
                {...this.props} 
                handleOpen={this.handleOpen} 
                generateNavGuide={this.generateNavGuide} 
                generateNavNews={this.generateNavNews}
            />
        );
    }
}

// export default Navbar;

function mapStateToProps(state) {
	return { download: state.download }
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(downloadActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);