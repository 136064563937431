import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import DownloadLink from './DownloadLink';

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as downloadActions from 'redux/actions/download';

class Download extends Component {
    render() {
        let {download, actions} = this.props;
        return (
            <Modal 
                open={download.is_open}
                onClose={() => actions.open_modal(false)}
                center
                classNames={{
                    modal: "modal_download",
                    closeButton: "modal_download__closeBtn"
                }}
            >
                <div className="modal_content">
                    <p>
                        {   
                            (this.props.lang.lang == "id") ?
                            "Pilih caramu untuk men-download Garena Speed Drifter" :
                            "Choose your way to download Garena Speed Drifter"
                        }
                    </p>
                    <DownloadLink {...this.props} />
                </div>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
	return { download: state.download }
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(downloadActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Download);