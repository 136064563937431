import React from "react";
import SEO from 'components/SEO/SEO';
import Navbar from 'components/Navbar/Navbar';
import Download from 'components/Download/Download';
import "assets/sass/all.scss";
import "assets/css/hamburgers.min.css";

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as langActions from 'redux/actions/lang';

class MainLayout extends React.Component {

	componentDidMount()
	{
		const pathname = window.location.pathname;
		let pathArray = pathname.split('/');
		let lang = pathArray[2];
		if (window.location.hostname === "localhost" || window.location.hostname === "10.10.118.21" || window.location.hostname === "0.0.0.0") {
			lang = pathArray[1]; 
		}
		this.props.actions.change_lang(lang);
	}

	render() {
		const { children, lang } = this.props;
		return (
			<div id="layout">
				<SEO />
				<Navbar lang={lang} />
				{children}
				<img className="img-fluid footer" src={require('assets/img/Home/Footer.png')} alt=""/>
				<Download lang={lang} />
			</div>
		);
	}
}

function mapStateToProps(state) {
	return { lang: state.lang }
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(langActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);